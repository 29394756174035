<script setup lang="ts">
import type { IForecastAssumptionFieldGroup } from '@reports'

const props = defineProps<{ group: IForecastAssumptionFieldGroup }>()

const hasFields = computed(
  () => props.group.fields && props.group.fields.length > 0,
)

const isExpanded = ref(true)
const toggleExpand = () => {
  isExpanded.value = !isExpanded.value
}
const dayjs = useDayjs()
const { dateFormat: divisionDateFormat } = useDivisions()

function formatValue(value: string, dataType: string): string {
  if (!value || value === '-') return '-'

  if (!dataType) return value

  if (dataType.toLocaleLowerCase() === 'date') {
    return dayjs(value, 'YYYY-MM-DD').format(divisionDateFormat.value)
  }

  if (dataType.toLocaleLowerCase() === 'decimal') {
    return new Intl.NumberFormat().format(Number(value))
  }

  return value
}
</script>

<template>
  <section v-auto-animate class="border-gray-750 rounded border bg-gray-700/30">
    <!-- Header -->

    <div
      class="bg-gray-850 sticky top-0 flex justify-between overflow-hidden rounded-t px-5 py-2.5"
      :class="{
        'rounded-b': !isExpanded,
      }"
    >
      <h3 class="text-sm font-semibold text-white">{{ group.name }}</h3>
      <Button
        color="transparentSecondary"
        class="h-6 w-6 cursor-pointer px-0 py-0"
        @click="toggleExpand"
      >
        <Icon :name="isExpanded ? 'chevron-up' : 'chevron-down'" />
      </Button>
    </div>

    <!-- Body -->

    <div v-if="isExpanded" class="overflow-hidden rounded-b">
      <div v-if="hasFields" class="divide-y divide-gray-700/50">
        <!-- Base/Current value -->
        <template
          v-if="group.fields?.some((field) => typeof field.value === 'object')"
        >
          <header class="flex px-4 py-1 text-left text-xs">
            <span class="w-1/3 p-1 text-gray-500"> </span>
            <span class="w-1/3 p-1 font-medium text-gray-500">{{
              group.fields?.[0]?.value?.current?.displayName ?? 'Current'
            }}</span>
            <span class="w-1/3 p-1 font-medium text-gray-500">{{
              group.fields?.[0]?.value?.original?.displayName ?? 'Initial'
            }}</span>
          </header>

          <div
            v-for="field in group.fields"
            :key="field.displayName"
            class="flex px-4 py-1 text-left text-sm"
          >
            <span class="w-1/3 p-1">{{ field.displayName }}</span>

            <!-- Current -->
            <span class="w-1/3 p-1 font-medium">
              <template v-if="typeof field.value === 'object'">
                {{
                  formatValue(
                    field.value?.current?.value,
                    field.value?.current?.dataType,
                  )
                }}
              </template>
              <template v-else>
                {{ formatValue(field.value, field.dataType) }}
              </template>
            </span>

            <!-- Initial -->
            <span class="w-1/3 p-1 font-medium">
              <template v-if="typeof field.value === 'object'">
                {{
                  formatValue(
                    field.value?.original?.value,
                    field.value?.original?.dataType,
                  )
                }}
              </template>
              <template v-else>
                {{ formatValue(field.value, field.dataType) }}
              </template>
            </span>
          </div>
        </template>

        <!-- String value -->
        <template v-else>
          <div
            v-for="field in group.fields"
            :key="field.displayName"
            class="flex px-4 py-1 text-left text-sm"
          >
            <span class="w-6/12 p-1">{{ field.displayName }}</span>
            <!-- TODO: format values -->
            <span class="w-6/12 p-1 font-medium">
              <!-- {{ divisionDateFormat }} -->
              {{ formatValue(field.value, field.dataType) }}
            </span>
          </div>
        </template>
      </div>
      <div v-else class="text-gray-450 p-4 text-center">No data</div>
    </div>
  </section>
</template>
